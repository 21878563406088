<template>

  <b-row
    class="match-height"
  >
    <b-col
      v-if="$can('new', 'crm_leads_contact') || $can('new', 'crm_leads')"
      cols="6"
      md="12"
      lg="6"
      xl="4"
    >
      <b-card
        class="icon-card text-center cursor-pointer"
        @click.stop.prevent="$emit('createNewContactLead')"
      >
        <div class="icon-wrapper">
          <feather-icon
            :icon="getNameButtonCreateContactLead.icon"
            size="24"
          />
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <b-card-text
          class="icon-name text-truncate mb-0 mt-1 font-small-3"
          v-html="getNameButtonCreateContactLead.txt"
        />
        <!--eslint-enable-->
      </b-card>
    </b-col>

    <b-col
      v-if="$can('new', 'crm_visitas')"
      cols="6"
      md="12"
      lg="6"
      xl="4"
    >
      <b-card
        class="icon-card text-center cursor-pointer"
        @click.stop.prevent="$router.push({ name: 'crm-visitas-new' })"
      >
        <div class="icon-wrapper">
          <feather-icon
            icon="MapPinIcon"
            size="24"
          />
        </div>
        <b-card-text
          class="icon-name text-truncate mb-0 mt-1 font-small-3"
          v-html="formatTxt($t('Nova visita'))"
        />
      </b-card>
    </b-col>

    <b-col
      v-if="$can('new', 'crm_propostas')"
      cols="6"
      md="12"
      lg="6"
      xl="4"
    >
      <b-card
        class="icon-card text-center cursor-pointer"
        @click.stop.prevent="$router.push({ name: 'crm-proposals-new' })"
      >
        <div class="icon-wrapper">
          <feather-icon
            icon="FileIcon"
            size="24"
          />
        </div>
        <b-card-text
          class="icon-name text-truncate mb-0 mt-1 font-small-3"
          v-html="formatTxt($t('Nova proposta'))"
        />
      </b-card>
    </b-col>

    <b-col
      v-if="$can('list', 'crm_leads_contact') || $can('list', 'crm_leads')"
      cols="6"
      md="12"
      lg="6"
      xl="4"
    >
      <b-card
        class="icon-card text-center cursor-pointer"
        @click.stop.prevent="$router.push({ name: 'crm-leads-listview' })"
      >
        <div class="icon-wrapper">
          <feather-icon
            icon="UsersIcon"
            size="24"
          />
        </div>
        <b-card-text
          class="icon-name text-truncate mb-0 mt-1 font-small-3"
          v-html="formatTxt($t('Base de Dados'))"
        />
      </b-card>
    </b-col>

    <b-col
      v-if="$can('list', 'crm_propostas')"
      cols="6"
      md="12"
      lg="6"
      xl="4"
    >
      <b-card
        class="icon-card text-center cursor-pointer"
        @click.stop.prevent="$router.push({ name: 'crm-proposals-listview' })"
      >
        <div class="icon-wrapper">
          <feather-icon
            icon="FileTextIcon"
            size="24"
          />
        </div>
        <b-card-text
          class="icon-name text-truncate mb-0 mt-1 font-small-3"
          v-html="formatTxt($t('Propostas'))"
        />
      </b-card>
    </b-col>

    <b-col
      cols="6"
      md="12"
      lg="6"
      xl="4"
    >
      <b-card
        class="icon-card text-center cursor-pointer"
        @click.stop.prevent="$router.push({ name: 'listings-listview-rede' })"
      >
        <div class="icon-wrapper">
          <feather-icon
            icon="HomeIcon"
            size="24"
          />
        </div>
        <b-card-text
          class="icon-name text-truncate mb-0 mt-1 font-small-3"
          v-html="formatTxt($t('Angariações da rede'))"
        />
      </b-card>
    </b-col>

    <b-col
      v-if="$can('new_pre', 'listings')"
      cols="6"
      md="12"
      lg="6"
      xl="4"
    >
      <b-card
        class="icon-card text-center cursor-pointer"
        @click.stop.prevent="$router.push({ name: 'listings-pre-new' })"
      >
        <div class="icon-wrapper">
          <feather-icon
            icon="HomeIcon"
            size="24"
          />
        </div>
        <b-card-text
          class="icon-name text-truncate mb-0 mt-1 font-small-3"
          v-html="formatTxt($t('Nova pré-angariação'))"
        />
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'
// import checkNested from 'check-nested'

export default {
  components: {
    BRow, BCol, BCard, BCardText,
  },
  computed: {
    getNameButtonCreateContactLead() {
      let oButton = {}

      if (this.$can('new', 'crm_leads_contact') && this.$can('new', 'crm_leads')) {
        oButton = {
          icon: 'UserPlusIcon',
          txt: this.formatTxt(this.$t('Novo contacto / Lead')),
        }
      } else if (this.$can('new', 'crm_leads_contact')) {
        oButton = {
          icon: 'UserPlusIcon',
          txt: this.formatTxt(this.$t('Novo contacto')),
        }
      } else if (this.$can('new', 'crm_leads')) {
        oButton = {
          icon: 'UserPlusIcon',
          txt: this.formatTxt(this.$t('Novo Lead')),
        }
      }

      return oButton
    },
  },
  methods: {
    formatTxt(text) {
      let sTxtHtml = ''
      if (text !== '') {
        const newText = text.split(' ')
        if (newText[0] !== '') {
          sTxtHtml += newText[0]
          newText.shift()
        }

        const sNewText = newText.join(' ')
        if (sNewText !== '') {
          sTxtHtml += '<br>'
          sTxtHtml += sNewText
        }
      }

      return sTxtHtml
    },
  },
}
</script>
