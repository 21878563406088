<template>

  <b-card
    no-body
    class="border-0 m-0 p-0"
  >
    <b-card-header class="p-1">
      <div class="d-flex align-items-center">
        <feather-icon
          size="24"
          class="mr-1"
          icon="EditIcon"
        />
        <b-card-title class="ml-25">
          {{ $t('Novo seguimento') }}
        </b-card-title>
      </div>
    </b-card-header>
    <b-card-body class="p-0">

      <validation-observer
        #default="{ }"
        ref="formSaveValidatorNovoSeguimento"
      >
        <b-form
          @submit.prevent
        >
          <b-container
            fluid
          >
            <b-row>
              <b-col md="12">
                <validation-provider
                  ref="leadsContactsValidation"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-model="sw030s03"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="listLeadsContactsApp"
                      append-to-body
                      :calculate-position="withPopper"
                      :loading="isLoadingLeadsContacts"
                      :filterable="false"
                      :placeholder="$t('Digite o nome do {tipoLead}', { tipoLead: `${$tc('Lead', 1)} / ${$tc('Contacto', 1)}`} )"
                      label="nome"
                      item-text="nome"
                      item-value="id"
                      @search="onSearchLeadsContactsAppSelect"
                    >
                      <div
                        v-if="listLeadsContactsApp.length > 0"
                        slot="no-options"
                      >
                        {{ $t('Sem resultados') }}
                      </div>
                      <template
                        v-if="listLeadsContactsApp.length === 0"
                        slot="no-options"
                      >
                        {{ $t('Digite o nome') }}
                      </template>
                      <template
                        slot="option"
                        slot-scope="option"
                      >
                        <div class="d-flex align-items-center">
                          <b-avatar
                            :src="option.photo"
                            class="mr-50"
                          />
                          <div class="detail">
                            <b-card-text class="mb-0">
                              {{ option.nome }}
                            </b-card-text>
                            <b-card-text
                              v-if="option.email"
                              class="mb-0 text-muted"
                            >
                              <small>{{ option.email }}</small>
                            </b-card-text>
                            <b-card-text
                              v-if="option.phone"
                              class="mb-0 text-muted"
                            >
                              <small>{{ option.phone }}</small>
                            </b-card-text>
                            <b-card-text
                              v-if="option.tipo"
                              class="mb-0 text-muted"
                            >
                              <small>{{ option.tipo }}</small>
                            </b-card-text>
                          </div>
                        </div>
                      </template>
                      <template
                        slot="selected-option"
                        slot-scope="option"
                      >
                        {{ option.nome }}
                      </template>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row
              v-if="formVars.tipos.length > 0"
            >
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <v-select
                      v-model="sw030s13"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="formVars.tipos"
                      append-to-body
                      :calculate-position="withPopper"
                      :clearable="true"
                      :placeholder="$t('Tipo de seguimento')"
                      label="sw090s02"
                      item-text="sw090s02"
                      item-value="sw090s01"
                    >
                      <template #option="{ sw090s02 }">
                        {{ sw090s02 }}
                      </template>
                      <div slot="no-options">
                        {{ $t('Sem resultados') }}
                      </div>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group>
                    <date-picker
                      ref="calendarSW030s04"
                      v-model="sw030s04"
                      value-type="format"
                      format="YYYY-MM-DD HH:mm"
                      :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                      type="datetime"
                      :editable="false"
                      :clearable="true"
                      :lang="langCalendar"
                      :append-to-body="true"
                      :show-second="false"
                      prefix-class="sw"
                      :placeholder="$t('Data do evento')"
                      @change="checkValidateDateTime"
                    >
                      <template #icon-clear>
                        <v-select-deselect />
                      </template>
                    </date-picker>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row
              v-if="sw030s04_time_show===true"
            >
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group>
                    <b-form-timepicker
                      v-model="sw030s04_time"
                      hide-header
                      now-button
                      :label-close-button="$t('Fechar')"
                      :label-now-button="$t('Hora atual')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :placeholder="$t('Hora do evento')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-textarea
                      v-if="modeComponentCreateDesc=='textarea'"
                      v-model="sw030s05"
                      rows="1"
                      no-resize
                      :placeholder="$t('Seguimento')"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    />

                    <quill-editor
                      v-if="modeComponentCreateDesc==='editor'"
                      v-model="sw030s05"
                      :options="{
                        modules: {
                          toolbar: '#quill-toolbar-new',
                        },
                        placeholder: $t('Seguimento'),
                        bounds: '#quill-toolbar-new',
                      }"
                      class="quil-content border-bottom-0"
                    />
                    <div
                      v-if="modeComponentCreateDesc==='editor'"
                      id="quill-toolbar-new"
                      class="quill-toolbar d-flex justify-content-end border-top-0"
                    >
                      <button
                        v-b-tooltip.hover.top="$t('Negrito')"
                        class="ql-bold"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Itálico')"
                        class="ql-italic"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Sublinhado')"
                        class="ql-underline"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Lista')"
                        class="ql-list"
                        value="ordered"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Lista numerada')"
                        class="ql-list"
                        value="bullet"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Remover indentação')"
                        class="ql-indent"
                        value="-1"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Indentar')"
                        class="ql-indent"
                        value="+1"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Inserir link')"
                        class="ql-link"
                      />
                      <button
                        v-b-tooltip.hover.top="$t('Remover formatação')"
                        class="ql-clean"
                      />
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  block
                  @click.stop.prevent="saveForm"
                >
                  {{ $t('Criar seguimento') }}
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </validation-observer>

    </b-card-body>
  </b-card>

</template>

<script>
import store from '@/store'
import {
  BFormGroup,
  BButton,
  BRow,
  BCol,
  BForm,
  BContainer,
  BFormTimepicker,
  VBTooltip,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardText,
  BAvatar,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction, debounce } from '@core-custom/mixins/geral'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { onUnmounted } from '@vue/composition-api'
import modelSeguimentos from '@store-modules/crm/leads/seguimentos'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import checkNested from 'check-nested'
import { quillEditor } from '@/views/modules/components/editor-html'
import { mask } from 'vue-the-mask'
import modelSW025 from '@store-modules/crm/leads/data'
import btoa from 'btoa'
import { mixFormGeral } from '../../mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BContainer,
    vSelect,
    BFormTimepicker,
    quillEditor,
    DatePicker: oDatepicker,
    vSelectDeselect: vSelect.components.Deselect,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BAvatar,
    BFormTextarea,
  },
  directives: {
    Ripple,
    mask,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  data() {
    return {
      langCalendar: {},

      sw030s05: null,
      sw030s02: null,
      sw030s14: false,

      // Evento normal
      sw030s13: null,
      sw030s04: null,
      sw030s04_time: null,
      sw030s04_time_show: false,
      sw030s18: null,

      // Lead / Contact
      listLeadsContactsApp: [],
      isLoadingLeadsContacts: false,
      sw030s03: null,

      modeComponentCreateDesc: 'textarea', // editor
    }
  },
  computed:
    {
      ...mapGetters('seguimentos', ['formVars']),
    },
  async mounted() {
    await this.$store.dispatch('seguimentos/popupAddSeguimento', {
      keyReg: '',
      typeLead: '',
    }).then(async () => {
      await this.importLocale(this.$i18n.locale).then(res => {
        this.langCalendar = res.default
      })
    }).catch(error => {
      this.showMsgErrorRequest(error)
    })
  },
  methods: {
    async saveForm() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorNovoSeguimento.validate()
          .then(async valid => {
            if (valid) {
              const formDataSeguimento = new FormData()
              formDataSeguimento.append('sw030s08', '')

              if (checkNested(this.sw030s03, 'id') && (this.sw030s03.id !== '')) {
                formDataSeguimento.append('sw030s03', btoa(this.sw030s03.id))
              }

              if (checkNested(this.sw030s02, 'id') && (this.sw030s02.id !== '')) {
                formDataSeguimento.append('sw030s02', this.sw030s02.id)
              }

              formDataSeguimento.append('sw030s05', this.sw030s05 || '')

              formDataSeguimento.append('sw030s14', 0)

              if (checkNested(this.sw030s13, 'sw090s01') && (this.sw030s13.sw090s01 !== '')) {
                formDataSeguimento.append('sw030s13', this.sw030s13.sw090s01)
              }

              if (this.sw030s04 !== '') {
                const aSW030s04 = this.sw030s04.split(' ')

                if (aSW030s04.length === 2) {
                  formDataSeguimento.append('sw030s04', aSW030s04[0] || '')

                  if (aSW030s04[1] === '00:00') {
                    formDataSeguimento.append('sw030s18', 1)
                  } else {
                    formDataSeguimento.append('sw030s18', 0)
                    formDataSeguimento.append('sw030s04_time', aSW030s04[1] || '')
                  }
                } else {
                  formDataSeguimento.append('sw030s04', this.sw030s04 || '')
                }
              }

              self.$root.$emit('app::loading', true)
              this.$store.dispatch('seguimentos/saveSeguimento', formDataSeguimento).then(res => {
                self.$root.$emit('app::loading', false)
                self.showMsgSuccessRequest(res)
                self.clearForm()
                self.$emit('refresh')
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Tem de preencher os campos do seguimento')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Tem de preencher os campos do seguimento')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    async clearForm() {
      try {
        this.sw030s05 = null
        this.sw030s02 = null
        this.clearLeadContact()
        this.clearFieldsWithoutRoutine()
      } catch (err) {
        this.showMsgErrorRequest({ message: this.$t('Problema ao limpar formulário') })
      }
    },
    clearFieldsWithoutRoutine() {
      this.sw030s14 = false
      this.sw030s13 = null
      this.sw030s04 = null
      this.sw030s04_time = null
      this.sw030s04_time_show = false
      this.sw030s18 = null
      this.$refs.formSaveValidatorNovoSeguimento.reset()
    },

    // Eventos para escolher o Lead / Contacto
    clearLeadContact() {
      this.sw030s03 = null
      this.listLeadsContactsApp = []
    },
    onSearchLeadsContactsAppSelect(search, loading) {
      if (search.length) {
        loading(true)
        this.searchLeadsContactsAppSelect(loading, search, this)
      }
    },
    searchLeadsContactsAppSelect: debounce((loading, search, vm) => {
      const oThis = vm

      vm.$store.dispatch('sw025/searchLeadsContactoByUserAuth', { text: search }).then(res => {
        oThis.listLeadsContactsApp = res
        loading(false)
      }).catch(error => {
        vm.showMsgErrorRequest(error)
        oThis.listLeadsContactsApp = []
        loading(false)
      })
    }, 500),
    checkValidateDateTime(date, type) {
      if (type === 'minute') {
        this.$refs.calendarSW030s04.closePopup()
      }
    },
  },
  setup() {
    const MODEL_SEGUIMENTOS = 'seguimentos'
    const MODEL_SW025 = 'sw025'

    if (!store.hasModule(MODEL_SEGUIMENTOS)) {
      store.registerModule(MODEL_SEGUIMENTOS, modelSeguimentos)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SEGUIMENTOS)) store.unregisterModule(MODEL_SEGUIMENTOS)
      })
    }

    if (!store.hasModule(MODEL_SW025)) {
      store.registerModule(MODEL_SW025, modelSW025)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SW025)) store.unregisterModule(MODEL_SW025)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + .quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

::v-deep .ql-snow .ql-tooltip {
  white-space: normal !important;
}
</style>
