<template>

  <b-card
    :img-src="require('@/assets/images/zome/ranking_icon.svg')"
    :img-alt="$t('Rating')"
    img-top
    class="card-rating-dashboard"
  >
    <b-card-text>
      {{ $t('Rating Zome') }}
    </b-card-text>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      @click.stop.prevent="openModalRatingIframe"
    >
      {{ $t('Consultar') }}
    </b-button>

    <component
      :is="ModalRatingIframe"
      v-if="showModalRatingIframe===true"
      ref="modalRatingIframe"
      @closeModal="closeModalRatingIframe"
    />
  </b-card>

</template>

<script>
import {
  BCard, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { activatedScrollBody, disabledScrollBody } from '@core-custom/mixins/geral'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  methods: {
    openModalRatingIframe() {
      disabledScrollBody()
      this.showModalRatingIframe = true
    },
    closeModalRatingIframe() {
      this.showModalRatingIframe = false
      activatedScrollBody()
    },
  },
  setup() {
    const ModalRatingIframe = () => import(/* webpackChunkName: "modalRatingIframe" */ /* webpackPrefetch: true */ '../modal/rating.vue')

    return {
      ModalRatingIframe,
      showModalRatingIframe: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.card-rating-dashboard img{
  max-height: 280px!important;
}
</style>
