import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewInit: state => state.listviewInit.value,
    listviewData: state => state.listviewData.value,
    totalRows: state => state.totalRows.value,
    perPage: state => state.perPage.value,
    tabSelectFilter: state => state.tabSelectFilter.value,
    loadingListview: state => state.loadingListview.value,
    currentPage: state => state.currentPage.value,
    searchTxt: state => state.searchTxt.value,
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_search: state.listiview_txt_search,
      txt_loading: state.listview_txt_loading,
    }),
    filterSelected: state => ({
      estado: state.estado.value,
      tipoNotificacao: state.tipoNotificacao,
      notificationID: state.notificationID,
    }),
    filterData: state => ({
      tiposNotificacao: state.tiposNotificacao.value,
    }),
    positionListview: state => state.positionListview,
    showPager: state => {
      if (state.totalRows.value > state.perPage.value) {
        return true
      }

      return false
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setFilterData(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setTypeNotificacion(state, payload) {
      state.tiposNotificacao.value = payload
    },
    setState(state, payload) {
      state.estado.value = payload
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    setSelectedNotificationID(state, payload) {
      state.notificationID.value = payload ? state.listviewData.value.map(row => row.id) : []
    },
    setFilterNotification(state, payload) {
      state.tipoNotificacao.value = payload || 'all'
    },
    savePositionListview(state, payload) {
      state.positionListview = payload || {}
    },
    setHistoryKeyStorage(state, payload) {
      state.historyKeyStorage = payload || ''
    },
    saveStates(state) {
      if (state.historyKeyStorage !== '') {
        const oStatesHistory = []

        state.statesToLocalStorage.forEach(elm => {
          if (state[elm] !== undefined) {
            oStatesHistory.push({
              key: elm,
              value: state[elm],
            })
          }
        })

        localStorage.setItem(state.historyKeyStorage, JSON.stringify(oStatesHistory))
      }
    },
    initStates(state) {
      if (state.historyKeyStorage !== '') {
        if ((localStorage.getItem(state.historyKeyStorage) !== null) && (localStorage.getItem(state.historyKeyStorage) !== '')) {
          try {
            const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))

            if (stateCustom.length > 0) {
              stateCustom.forEach(field => {
                if (('key' in field) && ('value' in field)) {
                  if (state.statesToLocalStorage.includes(field.key)) {
                    Object.assign(state[field.key], field.value)
                  }
                }
              })
            }
          } catch (err) {
            localStorage.removeItem(state.historyKeyStorage)
            //
          }
        }
      }
    },
  },
  actions: {
    // Carregar listagem
    async fetchListView({
      state, commit, dispatch,
    }, payload) {
      const listviewFilterParams = new FormData()

      if (state.tipoNotificacao.value !== '') {
        listviewFilterParams.append('viewTab', state.tipoNotificacao.value)
      }

      listviewFilterParams.append('fsw041s06', state.estado.value)

      if (state.searchTxt.value !== '' && state.searchTxt.value !== null) {
        listviewFilterParams.append('fSearch', state.searchTxt.value)
      }

      listviewFilterParams.append('fstartLetter', 'all')
      listviewFilterParams.append('loadContentRecords', 1)
      listviewFilterParams.append('start', ((state.currentPage.value - 1) * state.perPage.value))
      listviewFilterParams.append('length', state.perPage.value)

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        commit('setListviewInit', 1)
      } else {
        commit('setListviewData', [])
        commit('setTotalRows', 0)
        commit('setLoadingListview', true)
        commit('setListviewInit', 1)
      }

      commit('setSelectedNotificationID', false)

      await dispatch('request/cancelTokensPendingByUrl', 'notification/load', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}notification/load`, listviewFilterParams)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setListviewData', response.data.data)
              commit('setTotalRows', response.data.recordsTotal)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              resolve(true)
            } else {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error('Problema ao carregar a listagem'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    // Obter os filtros da listagem
    async getDataFiltersListview({ commit }) {
      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}notification`)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setTypeNotificacion', response.data.data.tabs)
              resolve(true)
            } else {
              reject(new Error(i18n.t('Problema ao carregar o filtro')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o filtro')))
            }
          })
      })
    },

    // Marcar/Desmarcar notificações
    async changeMarkStatus({ dispatch }, payload) {
      dispatch('request/cancelTokensPendingByUrl', 'notification/setStatus', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}notification/setStatus`, payload)
          .then(response => {
            if (response.data.error !== 1) {
              resolve(response.data)
            } else {
              reject(new Error('Problema a marcar/desmarcar notificações'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a marcar/desmarcar notificações')))
            }
          })
      })
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView')
    },

    pullToRefreshListview({ commit, dispatch }) {
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    setEstado({ commit, dispatch }, value) {
      commit('setState', value)
      return dispatch('fetchListView')
    },

    setNotificationIDSelected({ commit }, idNotifi) {
      commit('setSelectedNotificationID', idNotifi)
    },

    setFilterNotification({ commit, dispatch }, typeNotifi) {
      commit('setFilterNotification', typeNotifi)
      return dispatch('fetchListView')
    },

    markAllNotificationView({ dispatch }) {
      dispatch('request/cancelTokensPendingByUrl', 'notification/setStatus', { root: true })

      const paramFilter = new FormData()
      paramFilter.append('eventAction', 'mark_all_view')

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}notification/setStatus`, paramFilter)
          .then(response => {
            if (response.data.error !== 1) {
              resolve(response.data.msg)
            } else {
              reject(new Error(response.data.msg))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a marcar as notificações como vistas')))
            }
          })
      })
    },

    async getTotalNotificationNotView() {
      const listviewFilterParams = new FormData()
      listviewFilterParams.append('viewTab', 'all')
      listviewFilterParams.append('fsw041s06', 0)
      listviewFilterParams.append('fstartLetter', 'all')
      listviewFilterParams.append('loadContentRecords', 1)

      return new Promise(resolve => {
        api.post(`${apiConfig.url_base_api}notification/load`, listviewFilterParams)
          .then(response => {
            if (response.data.data !== undefined) {
              resolve(response.data.recordsTotal)
            } else {
              resolve(0)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              resolve(0)
            }
          })
      })
    },

  },
}
