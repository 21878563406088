<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="8"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            {{ $t('Faturação') }}
          </h4>
        </div>

        <vue-apex-charts
          type="bar"
          height="230"
          :options="chartBar.chartOptions"
          :series="chartBar.series"
        />
      </b-col>

      <b-col
        md="4"
        class="budget-wrapper"
      >
        <b-dropdown
          v-if="aFilterLastMonthChart.length > 0"
          :text="filterLastMonthChart.txt"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="row in aFilterLastMonthChart"
            :key="`filter-last-month-${row.month}`"
            @click.stop.prevent="chooseFilterLastMonth(row)"
          >
            {{ row.txt }}
          </b-dropdown-item>
        </b-dropdown>

        <h2 class="mb-25">
          {{ formatPrice(invoiceData.total_mask) || 0 }}{{ symbolMoney }}
        </h2>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bolder mr-25">{{ $t('YOY') }}</span>
        </div>

        <vue-apex-charts
          type="line"
          height="80"
          :options="chartLine.options"
          :series="chartLine.series"
        />

        <div
          v-if="invoiceData.txt_interval_current_year"
          class="d-flex flex-column"
        >
          <div class="d-flex align-items-center mb-1">
            <span
              class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer"
              :style="{ backgroundColor: colorCurrentYear }"
            />
            <span>{{ invoiceData.txt_interval_current_year }}</span>
          </div>
          <div
            v-if="invoiceData.txt_interval_previous_year"
            class="d-flex align-items-center"
          >
            <span
              class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"
              :style="{ backgroundColor: colorPreviousYear }"
            />
            <span>{{ invoiceData.txt_interval_previous_year }}</span>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-overlay
      :show="isLoading"
      rounded="sm"
      no-wrap
    />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { ref, computed, watch } from '@vue/composition-api'
import { symbolMoney } from '@core-custom/utils/money'
import checkNested from 'check-nested'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCard,
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      formatPrice,
    }
  },
  methods: {
    async chooseFilterLastMonth(row) {
      this.filterLastMonthChart = row
      this.isLoading = true
      await this.$emit('refreshInvoice', row.month || 6)
    },
  },
  setup(props) {
    const { t } = useI18nUtils()

    const isLoading = ref(true)

    const aFilterLastMonthChart = []
    aFilterLastMonthChart.push({
      month: 6,
      txt: t('últimos 6 meses'),
    })
    aFilterLastMonthChart.push({
      month: 12,
      txt: t('últimos 12 meses'),
    })

    const infoFilterLastMonthChart = aFilterLastMonthChart.find(o => o.month === 6)
    const filterLastMonthChart = ref({})

    if (filterLastMonthChart !== undefined) {
      filterLastMonthChart.value = infoFilterLastMonthChart
    }

    const colorCurrentYear = '#7367f0'
    const colorPreviousYear = '#dcdae3'

    // Grafico linhas
    const chartLine = {
      series: [],
      options: {
        chart: {
          height: 80,
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          sparkline: { enabled: true },
        },
        stroke: {
          curve: 'smooth',
          width: [2],
        },
        colors: [colorCurrentYear, colorPreviousYear],
        tooltip: {
          enabled: true,
        },
        xaxis: {
          categories: [],
        },
      },
    }

    // Grafico barras
    const chartBar = {
      series: [],
      chartOptions: {
        chart: {
          stacked: true,
          type: 'bar',
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: false },
          },
        },
        xaxis: {
          categories: [''],
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.86rem',
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [colorCurrentYear, colorPreviousYear],
        plotOptions: {
          bar: {
            columnWidth: '17%',
            endingShape: 'rounded',
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.86rem',
            },
          },
        },
      },
    }

    const invoiceData = computed(() => props.data)

    watch(invoiceData, () => {
      // Grafico de linhas
      chartLine.series = []
      chartLine.options.xaxis.categories = []

      if (checkNested(invoiceData.value, 'currentYear')) {
        const aValuesCurrentYear = []
        const aAbbrMonths = []
        invoiceData.value.currentYear.forEach(row => {
          aValuesCurrentYear.push(row.value)
          aAbbrMonths.push(row.month_abbr)
        })

        chartLine.options.xaxis.categories = aAbbrMonths

        chartLine.series.push({
          name: invoiceData.value.txt_interval_current_year,
          data: aValuesCurrentYear,
        })
      }

      if (checkNested(invoiceData.value, 'previousYear')) {
        const aValuesPreviousYear = []
        invoiceData.value.previousYear.forEach(row => {
          aValuesPreviousYear.push(row.value)
        })

        chartLine.series.push({
          name: invoiceData.value.txt_interval_previous_year,
          data: aValuesPreviousYear,
        })
      }

      chartLine.options = { ...chartLine.options }

      // Grafico de barras
      chartBar.series = []
      chartBar.chartOptions.xaxis.categories = []

      if (checkNested(invoiceData.value, 'currentYear')) {
        const aValuesCurrentYear = []
        const aAbbrMonths = []
        invoiceData.value.currentYear.forEach(row => {
          aValuesCurrentYear.push(row.value)
          aAbbrMonths.push(row.month_abbr)
        })

        chartBar.chartOptions.xaxis.categories = aAbbrMonths

        chartBar.series.push({
          name: invoiceData.value.txt_interval_current_year,
          data: aValuesCurrentYear,
        })

        chartBar.chartOptions = { ...chartBar.chartOptions }
      }

      isLoading.value = false
    })

    return {
      aFilterLastMonthChart,
      filterLastMonthChart,

      invoiceData,
      symbolMoney,

      colorCurrentYear,
      colorPreviousYear,

      chartLine,
      chartBar,

      isLoading,
    }
  },
}
</script>
