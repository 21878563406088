<template>
  <div style="height: inherit">

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list position-relative space-bottom">

      <!-- Barra pesquisa / ordenação -->
      <div
        class="app-fixed-search d-flex align-items-center"
      >

        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            :size="sizeIconsAction"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxt"
              value=""
              :placeholder="listview_txt_default.txt_search"
            />
          </b-input-group>
        </div>
      </div>

      <!-- Barra de ações dos registos da listagem -->
      <div
        v-if="listviewData.length > 0"
        class="app-action"
      >
        <div class="action-left align-items-center d-flex">
          <b-form-checkbox
            :checked="selectAllNotificationCheckbox"
            :indeterminate="isselectAllNotificationCheckboxIndeterminate"
            @change="selectAllCheckboxUpdate"
          >
            {{ $t('Selecionar tudo') }}
          </b-form-checkbox>
        </div>
        <div
          v-show="filterSelected.notificationID.value.length"
          class="align-items-center"
          :class="{'d-flex': filterSelected.notificationID.value.length}"
        >
          <feather-icon
            v-show="$route.params.folder !== 'visto'"
            v-b-tooltip
            :icon="((filterSelected.estado === '1') ? 'EyeIcon' : 'CheckCircleIcon')"
            size="17"
            class="cursor-pointer ml-1"
            :title="((filterSelected.estado === '1') ? $t('Marcar como não vista') : $t('Marcar como vista'))"
            @click="changeMarkSelected(filterSelected.estado)"
          />
        </div>
      </div>

      <!-- Listagem -->
      <div class="email-user-list scroll-area">
        <vue-pull-refresh
          ref="listviewScroll"
          :on-refresh="listViewRefresh"
          :config="{
            errorLabel: $t('Problema ao atualizar'),
            startLabel: $t('Baixar para atualizar'),
            readyLabel: $t('Solte para atualizar'),
            loadingLabel: $t('A atualizar...'),
            pullDownHeight: 60,
          }"
          @scroll.native="listViewSavePositionScroll"
        >
          <ul
            class="email-media-list"
          >
            <b-media
              v-for="row in listviewData"
              :key="row.id"
              tag="li"
              no-body
              class="cursor-default"
            >
              <b-media-aside class="media-left mr-50">
                <div class="user-action">
                  <b-form-checkbox
                    :checked="filterSelected.notificationID.value.includes(row.id)"
                    @change="toggleSelectedNotification(row.id)"
                    @click.native.stop
                  />
                </div>
              </b-media-aside>
              <b-media-body
                class="cursor-default"
              >
                <div
                  :class="{'cursor-pointer': ((row.link_app_mobile !== '') ? true : false), 'w-100': true }"
                  @click.stop.prevent="openNotification(row.link_app_mobile, row.id)"
                >
                  <div class="mail-details">
                    <div
                      class="mail-items w-100"
                    >
                      <h5
                        v-if="row.title"
                        class="mb-25"
                        :class="{'text-danger': ((row.critical === '1') ? true : false) }"
                      >
                        {{ row.title }}
                      </h5>
                      <span
                        v-if="row.msg"
                        :class="{'text-danger': ((row.critical === '1') ? true : false) }"
                      >
                        {{ row.msg }}
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="row.date"
                    class="mail-message"
                  >
                    <p class="text-truncate mb-50">
                      {{ row.date }}
                    </p>
                  </div>
                  <div
                    v-if="row.name_for"
                    class="mail-message"
                  >
                    <p class="text-truncate mb-0">
                      <feather-icon
                        v-b-tooltip
                        icon="UserIcon"
                        :title="$t('Para o utilizador')"
                      />
                      {{ row.name_for }}
                    </p>
                  </div>
                  <div
                    v-if="row.name_to"
                    class="mail-message"
                  >
                    <p class="text-truncate mb-0">
                      <feather-icon
                        v-b-tooltip
                        icon="UserIcon"
                        :title="$t('Do utilizador')"
                      />
                      {{ row.name_to }}
                    </p>
                  </div>
                </div>
                <div class="mail-message mt-2 text-left">
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      @click.stop.prevent="changeMark(row.id)"
                    >
                      <feather-icon
                        :icon="((filterSelected.estado === '1') ? 'EyeIcon' : 'CheckCircleIcon')"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ ((filterSelected.estado === '1') ? $t('Marcar como não vista') : $t('Marcar como vista')) }}</span>
                    </b-button>
                  </b-button-group>
                </div>
              </b-media-body>
            </b-media>
          </ul>
          <div
            v-if="listviewData.length === 0"
            class="no-results"
            :class="{'show': !listviewData.length}"
          >
            <h5 v-if="(listviewInit === 0)">
              {{ listview_txt_default.txt }}
            </h5>
            <h5 v-if="listviewInit === 1">
              {{ listview_txt_default.txt_loading }}
            </h5>
          </div>
        </vue-pull-refresh>
      </div>

      <b-container
        v-if="listviewData.length > 0"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="mb-0 pagination-white pt-0_2 pb-0_2"
              :size="sizePaginationListviewResponsive"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-overlay
        :show="$store.getters['notification/loadingListview']"
        no-wrap
      />

    </div>

    <portal
      to="content-renderer-sidebar-left"
    >
      <listview-left-sidebar
        ref="leftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        :toggle-selected-notification="toggleSelectedNotification"
        :show-msg-error-request="showMsgErrorRequest"
        :mq-shall-show-left-sidebar="mqShallShowLeftSidebar"
        :mark-all-notification-view="markAllNotificationView"
        :show-button-mark-all-view-notif.sync="showButtonMarkAllViewNotif"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

  </div>

</template>

<script>
import store from '@/store'
import '@/libs/sweet-alerts'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  BMedia, BMediaAside, BMediaBody, BFormInput, BInputGroup, BInputGroupPrepend, BFormCheckbox, VBTooltip, BRow, BCol, BContainer, BPagination, BOverlay, BButton, BButtonGroup,
} from 'bootstrap-vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import notification from '@store-modules/notifications/index'
import { mapGetters } from 'vuex'
import { sizeIconsAction, showMsgRequest } from '@core-custom/mixins/geral'
import Ripple from 'vue-ripple-directive'
import VuePullRefresh from 'vue-pull-refresh'
import ListviewLeftSidebar from './LeftSidebar.vue'

export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BRow,
    BCol,
    BContainer,
    BPagination,
    BOverlay,
    ListviewLeftSidebar,
    VuePullRefresh,
    BButton,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, showMsgRequest],
  computed: {
    ...mapGetters('notification', ['listviewData', 'filterData', 'filterSelected', 'listviewOrderBy', 'totalRows', 'perPage', 'listview_txt_default', 'listviewInit', 'fieldsOrder', 'tabSelectFilter', 'positionListview']),
    searchTxt: {
      get() { return this.$store.getters['notification/searchTxt'] },
      set(newValue) {
        this.$store.dispatch('notification/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['notification/currentPage'] },
      set(newValue) {
        this.$store.dispatch('notification/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    selectAllNotificationCheckbox() {
      return this.listviewData.length && (this.listviewData.length === this.filterSelected.notificationID.value.length)
    },
    isselectAllNotificationCheckboxIndeterminate() {
      return (Boolean(this.filterSelected.notificationID.value.length) && this.listviewData.length !== this.filterSelected.notificationID.value.length)
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
  },
  watch: {
    $route() {
      this.$refs.leftSidebar.$refs.filterScroll.$el.scrollTop = 0
    },
  },
  beforeDestroy() {
    this.$store.commit('notification/savePositionListview', this.sPostionListview)
    this.$store.commit('notification/saveStates')
  },
  async created() {
    try {
      await this.$store.dispatch('notification/getDataFiltersListview').catch(error => {
        this.showMsgErrorRequest(error)
      })

      this.$store.commit('notification/initStates')

      await this.fetchListView()

      await this.$nextTick()

      if ((this.positionListview !== null) && (this.positionListview !== undefined)) {
        const { scrollTop, scrollLeft } = this.positionListview
        this.$refs.listviewScroll.$el.scrollTop = scrollTop
        this.$refs.listviewScroll.$el.scrollLeft = scrollLeft
      }
    } catch (err) {
      //
    }
  },
  methods: {
    selectAllCheckboxUpdate(val) {
      this.$store.dispatch('notification/setNotificationIDSelected', val)
    },
    toggleSelectedNotification(idNotifi) {
      const indexNotifi = this.filterSelected.notificationID.value.indexOf(idNotifi)
      if (indexNotifi === -1) {
        this.filterSelected.notificationID.value.push(idNotifi)
      } else {
        this.filterSelected.notificationID.value.splice(indexNotifi, 1)
      }
    },
    async listViewRefresh() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('notification/pullToRefreshListview')
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    async fetchListView() {
      await this.$store.dispatch('notification/fetchListView', { skipCurrentPage: true }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.$store.dispatch('notification/getTotalNotificationNotView').then(total => {
        if (total > 0) {
          this.showButtonMarkAllViewNotif = true
        } else {
          this.showButtonMarkAllViewNotif = false
        }
      })

      this.$root.$emit('notification::syncNotificationUser')
    },
    async changeMark(idNotifi, refresh = true, showAlert = true) {
      const formParams = new FormData()
      formParams.append('notif[]', idNotifi)

      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('notification/changeMarkStatus', formParams).then(async res => {
        this.$root.$emit('app::loading', false)
        if (showAlert === true) {
          this.showMsgSuccess({ message: res.msg })
        }

        if (refresh === true) {
          await this.fetchListView()
        }
      }).catch(error => {
        this.$root.$emit('app::loading', false)

        if (showAlert === true) {
          this.showMsgErrorRequest(error)
        }
      })
    },
    async changeMarkSelected() {
      const formParams = new FormData()

      if (this.filterSelected.notificationID.value.length > 0) {
        this.filterSelected.notificationID.value.forEach(val => {
          formParams.append('notif[]', val)
        })
      }

      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('notification/changeMarkStatus', formParams).then(async res => {
        this.$root.$emit('app::loading', false)
        this.showMsgSuccess({ message: res.msg })
        await this.fetchListView()
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(error)
      })
    },
    markAllNotificationView() {
      this.$swal({
        title: '',
        html: `${this.$t('Pretende marcar todas as notificações como vistas?')}`,
        icon: 'info',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$store.dispatch('notification/markAllNotificationView').then(async msgSuccess => {
            this.fetchListView()
            this.showMsgSuccessRequest({ message: msgSuccess })
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      })
    },
    async openNotification(url, notifID) {
      if (url !== '') {
        if (notifID !== '') {
          await this.changeMark(notifID, false, false)
        }

        await this.$router.push(url)
      }
    },
    listViewSavePositionScroll() {
      this.sPostionListview = {
        scrollTop: this.$refs.listviewScroll.$el.scrollTop,
        scrollLeft: this.$refs.listviewScroll.$el.scrollLeft,
      }
    },
  },
  setup() {
    const NOTIFICATION_MODULE_NAME = 'notification'

    if (!store.hasModule(NOTIFICATION_MODULE_NAME)) {
      store.registerModule(NOTIFICATION_MODULE_NAME, notification)

      onUnmounted(() => {
        if (store.hasModule(NOTIFICATION_MODULE_NAME)) store.unregisterModule(NOTIFICATION_MODULE_NAME)
      })
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const showButtonMarkAllViewNotif = ref(false)

    const sPostionListview = {
      scrollTop: 0,
      scrollLeft: 0,
    }

    return {
      mqShallShowLeftSidebar,
      sPostionListview,
      showButtonMarkAllViewNotif,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-app-list.space-bottom{
  padding-bottom: 2.8rem;

  @include media-breakpoint-down(xs) {
    padding-bottom: 3rem;
  }
}

/* Desativa as animação devido que provoca erro no scroll to refresh */
.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

.pull-down-container ::v-deep{
  .pull-down-header{
    background-color:inherit!important;
  }
  .pull-down-content{
    font-family:inherit!important;
    color:inherit!important;
    font-size:inherit!important;
  }
}

.cursor-default {
  cursor: default!important;
}
</style>
