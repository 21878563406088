<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{ $t('Topliner') }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="radialBar"
        height="275"
        :options="toplinerRadialBar.chartOptions"
        :series="toplinerRadialBar.series"
      />
    </b-card-body>

    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ $t('Real') }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-50">
          {{ result.atual }}
        </h3>
      </b-col>
      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ $t('Objectivo') }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ result.objectivo }}
        </h3>
      </b-col>
    </b-row>

    <b-overlay
      :show="isLoading"
      rounded="sm"
      no-wrap
    />

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BCardText, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { symbolMoney } from '@core-custom/utils/money'
import { showMsgRequest } from '@core-custom/mixins/geral'
import { ref, computed } from '@vue/composition-api'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCardText,
    BCol,
    BOverlay,
  },
  mixins: [showMsgRequest],
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      result: {},
      formatPrice,
      toplinerRadialBar: {
        series: [],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#5e5873',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
    }
  },
  watch: {
    dataTopLiner: {
      handler() {
        this.renderChart()
        this.isLoading = false
      },
      deep: true,
    },
  },
  methods: {
    renderChart() {
      this.result = {
        atual: `0 ${symbolMoney}`,
        objectivo: `0 ${symbolMoney}`,
      }

      try {
        // Carrega os valores da API
        if (Object.keys(this.dataTopLiner).length > 0) {
          if ('reachLimitTOP' in this.dataTopLiner) {
            const valueObj = this.formatToNumber(this.dataTopLiner.reachLimitTOP.ano.obj || 0)
            const valueReal = this.formatToNumber(this.dataTopLiner.reachLimitTOP.ano.real || 0)

            let valueRealFinal = (parseFloat(valueObj) - parseFloat(valueReal)) || 0
            let per = 0

            if (parseInt(valueRealFinal, 10) !== 0) {
              per = parseFloat((valueRealFinal * 100) / parseFloat(valueObj))
            }

            per = per.toFixed(0)

            valueRealFinal = valueRealFinal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            valueRealFinal = valueRealFinal.replaceAll('.', '#')
            valueRealFinal = valueRealFinal.replaceAll(',', '.')
            valueRealFinal = valueRealFinal.replaceAll('#', ',')

            this.result.atual = `${formatPrice(valueRealFinal)} ${symbolMoney}`
            this.result.objectivo = `${formatPrice(this.dataTopLiner.reachLimitTOP.ano.obj) || 0} ${symbolMoney}`
            this.toplinerRadialBar.series.push(per)
          }
        }
      } catch (err) {
        this.showMsgErrorRequest({ message: this.$t('Problema ao carregar o Topliner') })
      }
    },
    formatToNumber(value) {
      let sNumber = value.toString()
      sNumber = sNumber.replace('.', '')
      sNumber = sNumber.replace(',', '.')

      return Number(sNumber)
    },
  },
  setup(props) {
    const isLoading = ref(true)
    const dataTopLiner = computed(() => props.data)

    return {
      dataTopLiner,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
svg{
  path.apexcharts-radialbar-area{
    filter: none;
  }
}
</style>
