<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{ $t('Objectivos') }}
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        v-if="render===true"
        type="radialBar"
        :height="goalsRadialBar.value.chartOptions.chart.height"
        :options="goalsRadialBar.value.chartOptions"
        :series="goalsRadialBar.value.series"
      />
      <div
        v-for="(row, key, index) in result.value"
        :key="key"
        class="d-flex justify-content-between"
        :class="index === Object.keys(result.value).length - 1 ? '':'mb-1'"
      >
        <div class="series-info d-flex align-items-center">
          <span
            class="bullet bullet-warning svg-font-small-3 mr-50"
            :style="{backgroundColor: row.color}"
          />
          <span class="font-weight-bold text-capitalize ml-75">{{ row.txt }}</span>
        </div>
        <span>{{ row.value }} {{ row.sufix }} / {{ row.total }} {{ row.sufix }}</span>
      </div>
    </b-card-body>

    <b-overlay
      :show="isLoading"
      rounded="sm"
      no-wrap
    />

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { symbolMoney } from '@core-custom/utils/money'
import { showMsgRequest } from '@core-custom/mixins/geral'
import { ref, computed } from '@vue/composition-api'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
  },
  mixins: [showMsgRequest],
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      result: ref({}),
      render: false,
      formatPrice,
      goalsRadialBar: ref({
        series: [],
        chartOptions: {
          labels: [],
          plotOptions: {
            radialBar: {
              size: 150,
              hollow: {
                size: '10%',
              },
              track: {
                strokeWidth: '100%',
                margin: 15,
              },
              dataLabels: {
                show: (this.$store.state.capacitor.isNative !== true),
                value: {
                  fontSize: '1rem',
                  colors: '#5e5873',
                  fontWeight: '500',
                  offsetY: 5,
                },
              },
            },
          },
          colors: [],
          stroke: {
            lineCap: 'round',
          },
          chart: {
            height: 330,
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
        },
      }),
    }
  },
  watch: {
    dataGoals: {
      handler() {
        this.renderChart()
        this.isLoading = false
      },
      deep: true,
    },
  },
  methods: {
    renderChart() {
      this.render = false

      this.result.value = {
        inc: {
          color: '#E02021',
          txt: this.$t('INC'),
          value: 0,
          total: 0,
          sufix: symbolMoney,
        },
        leads: {
          color: '#7367F0',
          txt: this.$t('Leads'),
          value: 0,
          total: 0,
          sufix: '',
        },
        angariacoes: {
          color: '#28C76F',
          txt: this.$t('Angariações'),
          value: 0,
          total: 0,
          sufix: '',
        },
        /*
        propostas: {
          color: '#B620E0',
          txt: this.$t('Propostas'),
          value: 0,
          total: 0,
          sufix: '',
        },
        */
      }

      try {
        // Carrega os valores da API
        if (Object.keys(this.dataGoals).length > 0) {
          if ('INCtotal' in this.dataGoals) {
            this.result.value.inc.value = this.formatPrice(this.dataGoals.INCtotal.ano.real) || 0
            this.result.value.inc.total = this.formatPrice(this.dataGoals.INCtotal.ano.obj) || 0
          }

          if ('leads' in this.dataGoals) {
            this.result.value.leads.value = Number(this.dataGoals.leads.ano.real) || 0
            this.result.value.leads.total = Number(this.dataGoals.leads.ano.obj) || 0
          }

          if ('ang' in this.dataGoals) {
            this.result.value.angariacoes.value = Number(this.dataGoals.ang.ano.real) || 0
            this.result.value.angariacoes.total = Number(this.dataGoals.ang.ano.obj) || 0
          }

          /*
          if ('propostas' in this.dataGoals) {
            let totalPropostas = 0

            if ('efetuadas' in this.dataGoals.propostas.ano) {
              totalPropostas += Number(this.dataGoals.propostas.ano.efetuadas)
            }

            if ('recebidas' in this.dataGoals.propostas) {
              totalPropostas += Number(this.dataGoals.propostas.ano.recebidas)
            }

            this.result.value.propostas.value = Number(totalPropostas) || 0
            this.result.value.propostas.total = Number(this.dataGoals.propostas.ano.obj) || 0
          }
          */
        }
      } catch (err) {
        this.showMsgErrorRequest({ message: this.$t('Problema ao carregar os objetivos') })
      }

      // Preenche informação para o grafico
      const goalsRadialBarTemp = this.goalsRadialBar.value

      goalsRadialBarTemp.series = []
      goalsRadialBarTemp.chartOptions.labels = []
      goalsRadialBarTemp.chartOptions.colors = []

      if (Object.keys(this.result.value).length > 0) {
        Object.keys(this.result.value).forEach(tag => {
          // Converter valor para percentagem
          let percValue = 0

          if (this.result.value[tag].total > 0) {
            percValue = ((100 * this.formatToNumber(this.result.value[tag].value)) / this.formatToNumber(this.result.value[tag].total))

            if (!Number.isNaN(percValue)) {
              percValue = percValue.toFixed(2)
            } else {
              percValue = 0
            }
          } else if (this.result.value[tag].value > 0) {
            percValue = 100
          }

          goalsRadialBarTemp.series.push(percValue)
          goalsRadialBarTemp.chartOptions.labels.push(this.result.value[tag].txt)
          goalsRadialBarTemp.chartOptions.colors.push(this.result.value[tag].color)
        })
      }

      this.goalsRadialBar.value = { ...goalsRadialBarTemp }
      this.render = true
    },
    formatToNumber(value) {
      let sNumber = value.toString()
      sNumber = sNumber.replace('.', '')
      sNumber = sNumber.replace(',', '.')

      return Number(sNumber)
    },
  },
  setup(props) {
    const isLoading = ref(true)
    const dataGoals = computed(() => props.data)

    return {
      dataGoals,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
svg{
  path.apexcharts-radialbar-area{
    filter: none;
  }
}
</style>
