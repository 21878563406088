<template>

  <b-card
    v-if="$store.getters['auth/currentUserSession'].name"
    text-variant="center"
    class="card card-congratulations"
    body-class="pt-1 pb-1"
  >

    <b-img
      v-if="(dataStatus.total === 0) && (showInfo === true)"
      :src="require('@/assets/images/zome/effectLeft.png')"
      class="congratulations-img-left opacity-50 zindex-1"
      style="width:150px"
    />
    <b-img
      v-if="(dataStatus.total === 0) && (showInfo === true)"
      :src="require('@/assets/images/zome/effectRight.png')"
      class="congratulations-img-right opacity-50 zindex-1"
    />

    <h3
      class="mb-50 mt-50 text-white zindex-4 position-relative"
    >
      {{ $t('Olá') }} {{ $store.getters['auth/currentUserSession'].name }}
    </h3>

    <b-card-text
      v-if="(dataStatus.total > 0) && (showInfo === true)"
      class="m-auto w-100 zindex-4 position-relative"
    >
      {{ $t('Tem tarefas do dia para concluir.') }}
    </b-card-text>
    <b-card-text
      v-else
      class="m-auto w-100 zindex-4 position-relative"
    >
      {{ '\xa0' }}

    </b-card-text>

  </b-card>

</template>

<script>
import {
  BCard, BCardText, BImg,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCardText,
    BImg,
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  watch: {
    dataStatus: {
      handler(newVal) {
        if ((newVal !== null) && (newVal !== undefined) && ('total' in newVal)) {
          this.showInfo = true
        }
      },
      immediate: true,
    },
  },
  setup(props) {
    const dataStatus = computed(() => props.data)
    const showInfo = ref(false)

    return {
      dataStatus,
      showInfo,
    }
  },
}
</script>
