<template>

  <b-card
    no-body
    class="border-0 m-sm-0 p-0 mb-2"
    :style="{'min-height': '200px'}"
  >
    <b-card-header class="p-1">
      <b-card-title>
        <feather-icon
          size="24"
          class="mr-1"
          icon="ListIcon"
        />
        {{ $t('Seguimentos') }}
      </b-card-title>

      <b-dropdown
        v-if="fieldsFilter.statusDashboard.length > 0"
        :text="filterTabSelected.txt"
        size="sm"
        class="border-0"
        variant="outline-secondary"
        :style="{marginTop: '-15px'}"
      >
        <b-dropdown-item
          v-for="tab in fieldsFilter.statusDashboard"
          :key="`filter-tab-listings-${tab.tab}`"
          @click.stop.prevent="chooseFilterTab(tab)"
        >
          {{ tab.txt }}
        </b-dropdown-item>
      </b-dropdown>

    </b-card-header>

    <b-card-body
      class="p-0"
    >

      <div
        v-if="data.showListview() === true"
        class="mt-1 mb-2"
      >
        <list-data
          :data="listviewData.slice(0, 2)"
          :view-seguimento="viewSeguimento"
          :with-name-lead="showNameLead"
        />
      </div>

      <div
        v-if="listviewData.length === 0"
        class="no-results text-center"
        :class="{'show': !listviewData.length}"
      >
        <h5 v-if="(data.totalFieldsFilter > 0) && (data.init === 0)">
          {{ txt.notFind }}
        </h5>
        <h5 v-if="data.init === 1">
          {{ txt.loading }}
        </h5>
        <div v-if="(data.totalFieldsFilter===0) && (data.init === 0)">
          <b-alert
            class="mb-0 mt-1"
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>{{ txt.default }}</span>
            </div>
          </b-alert>
        </div>
      </div>

      <b-button
        v-if="data.totalRows > 2"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="outline-primary"
        class="mt-0 mb-0"
        @click.stop.prevent="openModalAllSeguimentos"
      >
        {{ $t('Ver todas') }}
      </b-button>

    </b-card-body>

    <b-overlay
      :show="data.loading"
      no-wrap
    />

    <view-seguimento
      v-if="showInfo===true"
      ref="modalInfoSeguimento"
      :row-data="infoSeguimento"
      :with-name-lead="showNameLead"
      :refresh-listview="refreshSeguimentos"
    />

    <component
      :is="ModalViewAllSeguimentos"
      v-if="showModalAllSeguimentos === true"
      ref="modalViewAllSeguimentos"
      :tab-selected="filterTabSelected"
      :show-name-lead="showNameLead"
      :refresh.sync="refreshModalAllSeguimentos"
      :visible.sync="visibleModalAllSeguimentos"
      @closeModal="closeModalAllSeguimentos"
      @viewSeguimento="viewSeguimento"
    />

  </b-card>

</template>

<script>
import store from '@/store'
import {
  BOverlay, VBTooltip,
  BCard, BCardHeader, BCardBody, BCardTitle, BDropdown, BDropdownItem, BButton, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import {
  onUnmounted, ref, computed,
} from '@vue/composition-api'
import modelSeguimentos from '@store-modules/crm/leads/seguimentos'
import { mixFormGeral } from '../../mixins'
import ListData from './listData.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BButton,
    BAlert,
    ListData,
    ViewSeguimento: () => import(/* webpackChunkName: "crm-leads-new-seguimento-view" */ /* webpackPrefetch: true */ './view.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    refresh: {
      type: Boolean,
      default: false,
      required: false,
    },
    viewDetail: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      showInfo: false,
      infoSeguimento: {},
      showNameLead: true,
      filterTabSelected: ref({}),
    }
  },
  computed:
  {
    ...mapGetters('seguimentosDashboard', ['listviewData', 'data', 'txt', 'fieldsFilter']),
  },
  watch: {
    refresh: {
      handler(newVal) {
        if (newVal === true) {
          this.refreshSeguimentos()
          this.$emit('update:refresh', false)
        }
      },
      immediate: true,
    },
    viewDetailRef: {
      immediate: true,
      handler(newVal) {
        if (newVal !== '') {
          this.$root.$emit('app::loading', true)
          this.$store.dispatch(`${this.NAMESPACE_SEGUIMENTO_FORM}/getInfoSeguimentoDashboard`, { id: newVal }).then(res => {
            this.$root.$emit('app::loading', false)
            this.viewSeguimento(res)
          }).catch(error => {
            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequest(error)
          })
        }
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      try {
        await this.$store.dispatch(`${this.NAMESPACE_SEGUIMENTO_FORM}/getTabsFilterDashboard`)

        const infoTabSelected = this.fieldsFilter.statusDashboard.find(o => o.tab !== undefined)
        if (infoTabSelected !== undefined) {
          this.filterTabSelected = infoTabSelected
        }

        await this.getTotalSeguimentosByDay()

        await this.getLastSeguimentos()
      } catch (err) {
        //
      }
    },
    async chooseFilterTab(tabSelected) {
      this.filterTabSelected = tabSelected
      await this.refreshSeguimentos()
    },
    async getTotalSeguimentosByDay() {
      const currentUser = this.$store.getters['auth/currentUser']
      if ((currentUser !== null) && (currentUser !== undefined)) {
        if (('id_role' in currentUser) && ([4, 7].includes(Number(currentUser.id_role)))) {
          await this.$store.dispatch(`${this.NAMESPACE_SEGUIMENTO_FORM}/getTotalSeguimentosByDay`).then(res => {
            this.$emit('showStatusTashFinish', res)
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      }
    },
    async getLastSeguimentos() {
      await this.$store.dispatch(`${this.NAMESPACE_SEGUIMENTO_FORM}/loadListviewDataDashboardByTab`, {
        init: true,
        tab: this.filterTabSelected.tab,
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async refreshSeguimentos() {
      if (this.showModalAllSeguimentos === true) {
        this.refreshModalAllSeguimentos = true
      } else {
        await this.$store.dispatch(`${this.NAMESPACE_SEGUIMENTO_FORM}/resetListviewDataDashboardByTab`)
        await this.getTotalSeguimentosByDay()
        await this.getLastSeguimentos()
      }
    },
    viewSeguimento(row) {
      this.$emit('destroyScrollContent')
      this.showInfo = true
      this.infoSeguimento = row
    },
    closeView() {
      this.$emit('activeScrollContent')
      this.showInfo = false
      this.infoSeguimento = {}
      this.visibleModalAllSeguimentos = true
    },
    openModalAllSeguimentos() {
      this.$emit('destroyScrollContent')
      this.showModalAllSeguimentos = true
      this.visibleModalAllSeguimentos = true
      this.refreshModalAllSeguimentos = false
    },
    closeModalAllSeguimentos() {
      this.$emit('activeScrollContent')
      this.showModalAllSeguimentos = false
      this.visibleModalAllSeguimentos = false
      this.refreshModalAllSeguimentos = false
    },
  },
  setup(props) {
    const NAMESPACE_SEGUIMENTO_FORM = 'seguimentosDashboard'

    if (!store.hasModule(NAMESPACE_SEGUIMENTO_FORM)) {
      store.registerModule(NAMESPACE_SEGUIMENTO_FORM, modelSeguimentos)

      onUnmounted(() => {
        if (store.hasModule(NAMESPACE_SEGUIMENTO_FORM)) store.unregisterModule(NAMESPACE_SEGUIMENTO_FORM)
      })
    }

    const ModalViewAllSeguimentos = () => import(/* webpackChunkName: "dashboard-modal-view-all-seguimentos" */ /* webpackPrefetch: true */ './listAllModal.vue')
    const showModalAllSeguimentos = ref(false)
    const visibleModalAllSeguimentos = ref(false)
    const refreshModalAllSeguimentos = ref(false)

    const viewDetailRef = computed(() => props.viewDetail)

    return {
      NAMESPACE_SEGUIMENTO_FORM,

      ModalViewAllSeguimentos,
      showModalAllSeguimentos,
      visibleModalAllSeguimentos,
      refreshModalAllSeguimentos,

      viewDetailRef,
    }
  },
}
</script>
