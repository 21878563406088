import checkNested from 'check-nested'

export const buildDataToListiew = data => {
  const result = []

  if (Object.keys(data).length > 0) {
    Object.keys(data).forEach(index => {
      const oListing = {
        pid: '',
        tipologia: '',
        morada: '',
        price: '',
        currencyL: '',
        currencyR: '',
        image: '',
        percPriceDrop: '',
        id: '',
      }

      if (checkNested(data[index], 'sw012s01')) {
        oListing.id = data[index].sw012s01
      }

      if (checkNested(data[index], 'sw012s02')) {
        oListing.pid = data[index].sw012s02
      }

      if (checkNested(data[index], 'tipologia')) {
        oListing.tipologia = data[index].tipologia
      }

      if (checkNested(data[index], 'local')) {
        const aLocal = data[index].local.split(' - ')
        aLocal.shift()

        oListing.morada = aLocal.join(' - ')
      }

      if (checkNested(data[index], 'sw012v467_currencyL')) {
        oListing.currencyL = data[index].sw012v467_currencyL
      }

      if (checkNested(data[index], 'sw012v467_currencyR')) {
        oListing.currencyR = data[index].sw012v467_currencyR
      }

      if (checkNested(data[index], 'precoImovel')) {
        oListing.price = oListing.currencyL + data[index].precoImovel + oListing.currencyR
      }

      if (checkNested(data[index], 'img')) {
        oListing.image = data[index].img
      }

      if (checkNested(data[index], 'imageSrc')) {
        oListing.image = data[index].imageSrc
      }

      if (checkNested(data[index], 'valor_baixa_preco')) {
        oListing.percPriceDrop = `${data[index].valor_baixa_preco}%`
      }

      result.push(oListing)
    })
  }

  return result
}

export const _ = null
