import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  // Variaveis gerais da listagem
  listviewInit: ref(1),
  listviewData: ref([]),
  tabSelectFilter: ref(''),
  loadingListview: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listiview_txt_search: i18n.t('Pesquisar'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  filterSelectedTotalFields: ref(0),
  listviewSelectedFields: ref(0),

  tiposNotificacao: ref([]),

  tipoNotificacao: ref('all'),
  estado: ref(0),
  notificationID: ref([]),

  positionListview: {},

  // States para guardar em localstorage
  historyKeyStorage: '',
  statesToLocalStorage: [
    'tipoNotificacao',
    'estado',
    'notificationID',

    'currentPage',
    'searchTxt',
    'positionListview',
  ],
})

export default defaultState
