<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          :class="{'email-app-menu': true, 'sws-not-button-create': ((showButtonMarkAllViewNotif === true) ? false: true) }"
        >
          <div
            v-if="showButtonMarkAllViewNotif===true"
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              block
              @click="markAllNotificationView();$emit('close-left-sidebar');"
            >
              {{ $t('Marcar tudo como visto') }}
            </b-button>
          </div>

          <vue-perfect-scrollbar
            ref="filterScroll"
            class="sidebar-menu-list scroll-area"
            :settings="configSettingsScroll"
          >

            <!-- Estado -->
            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                {{ $t('Estado') }}
              </h6>
            </div>
            <b-list-group class="list-group-messages">
              <b-list-group-item
                :key="`filter-estado-naovisto`"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.estado !== '1') ? true : false)"
                @click.stop.prevent="setEstado('0');$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">
                  {{ $t('Não visto') }}
                </span>
              </b-list-group-item>
              <b-list-group-item
                :key="`filter-estado-historico`"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.estado === '1') ? true : false)"
                @click.stop.prevent="setEstado('1');$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">
                  {{ $t('Histórico') }}
                </span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tipo de notificação -->
            <div
              v-if="Object.keys(filterData.tiposNotificacao).length > 0"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Tipo de notificação') }}
              </h6>
            </div>
            <b-list-group
              v-if="Object.keys(filterData.tiposNotificacao).length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="(row, index) in filterData.tiposNotificacao"
                :key="`filter-notif-${index}`"
                :style="{cursor: 'pointer' }"
                :active="(([index].includes(filterSelected.tipoNotificacao.value)) ? true : false)"
                @click.stop.prevent="setFilterNotification(index);$emit('close-left-sidebar')"
              >
                <span
                  class="align-text-bottom line-height-1"
                  :class="{'text-danger': ((row.css==='notif_critical') ? true : false)}"
                >
                  {{ row.desc }}
                </span>
              </b-list-group-item>
            </b-list-group>

            <!-- Extra space -->
            <h6
              class="section-label mt-3 mb-1 px-2"
            >
              {{ '\xa0' }}
            </h6>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import {
  BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { configSettingsScroll } from '@core-custom/utils/ui'

export default {
  directives: {
    Ripple,
  },
  components: {
    BListGroup,
    BListGroupItem,
    BButton,
    VuePerfectScrollbar,
  },
  props: {
    markAllNotificationView: {
      type: Function,
      required: true,
    },
    showButtonMarkAllViewNotif: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('notification', ['listviewData', 'filterData', 'filterSelected']),
  },
  methods: {
    ...mapActions('notification', ['setEstado', 'setFilterNotification']),
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu.sws-not-button-create .sidebar-menu-list{
  height: calc(100%);
}
</style>
