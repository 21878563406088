<template>

  <b-card
    no-body
    class="card-employee-task"
  >

    <b-card-header>
      <b-card-title
        :class="{'text-danger': dropPrice }"
      >
        {{ title }}
      </b-card-title>

      <template v-if="showFilter===true">
        <b-dropdown
          :text="filterTabSelected.txt"
          size="sm"
          class="border-0"
          variant="outline-secondary"
          :style="{marginTop: '-15px'}"
        >
          <b-dropdown-item
            v-for="(tab, index) in aFilterTab"
            :key="`filter-tab-listings-${index}`"
            @click.stop.prevent="chooseFilterTabListing(tab)"
          >
            {{ tab.txt }}
          </b-dropdown-item>
        </b-dropdown>
      </template>

    </b-card-header>

    <b-card-body
      v-if="(init===false) && (isErrorRequest === false)"
    >
      <div v-if="listings.length === 0">
        <b-alert
          variant="warning"
          class="m-0"
          show
        >
          <div class="alert-body">
            {{ $t('Não tem angariações') }}
          </div>
        </b-alert>
      </div>

      <template v-if="listings.length > 0">
        <b-row>
          <b-col cols="12">
            <preview-item
              v-for="(listing, index) in listings.slice(0, 4)"
              :key="`listing-id-${index}`"
              :listing="listing"
              :index="index"
              :extra-class="['employee-task']"
              :show-link-detail="true"
              :open-view-listing="openViewListing"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="listings.length > 4"
        >
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="outline-primary"
              class="mt-2"
              @click.stop.prevent="viewAll"
            >
              {{ $t('Ver todas') }}
            </b-button>
          </b-col>
        </b-row>
      </template>

    </b-card-body>
    <b-card-body
      v-else
    >
      <div v-if="isErrorRequest === true">
        <b-alert
          variant="danger"
          show
          class="m-0"
        >
          <div
            class="alert-body"
          >
            {{ $t('Problema ao carregar a informação') }}
          </div>
        </b-alert>
      </div>
      <div v-else>
        <b-alert
          variant="dark"
          show
          class="m-0"
        >
          <div
            class="alert-body"
          >
            {{ $t('A carregar...') }}
          </div>
        </b-alert>
      </div>
    </b-card-body>

    <b-overlay
      :show="isLoading"
      rounded="sm"
      no-wrap
    />
  </b-card>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BRow, BCol, BButton, BDropdown, BDropdownItem, BOverlay, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { eventsCustomSelects } from '@core-custom/mixins/geral'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import checkNested from 'check-nested'
import PreviewItem from '@/views/modules/components/blockListingSimple.vue'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { buildDataToListiew } from '../helper'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay,
    PreviewItem,
    BAlert,
  },
  directives: {
    Ripple,
  },
  mixins: [eventsCustomSelects],
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    dropPrice: {
      type: Boolean,
      default: false,
      required: false,
    },
    showFilter: {
      type: Boolean,
      default: false,
      required: false,
    },
    data: {
      type: Object,
      default: () => {},
      required: false,
    },
    filterTab: {
      type: String,
      default: '',
      required: false,
    },
    error: {
      type: Boolean,
      default: false,
      required: false,
    },
    openViewListing: {
      type: Function,
      required: true,
    },
  },
  methods: {
    async chooseFilterTabListing(tabSelected) {
      this.filterTabSelected = tabSelected
      this.isLoading = true
      await this.$emit('getListings', tabSelected.id || '')
    },
    viewAll() {
      if (this.dropPrice === true) {
        this.$emit('openModalViewAll', this.filterTabSelected.id || '')
      } else {
        // Elimina o filtro em memoria
        localStorage.removeItem('listings-listview-list')

        let sNamePathRoute = 'listings-listview-rede'
        if (checkNested(this.filterTabSelected, 'id')) {
          const infoTab = this.aFilterTab.find(o => o.id === this.filterTabSelected.id)

          if (checkNested(infoTab, 'linkRoute')) {
            sNamePathRoute = infoTab.linkRoute
          }
        }

        this.$router.push({ name: sNamePathRoute })
      }
    },
  },
  setup(props) {
    const { t } = useI18nUtils()
    const { can } = useAclUtils()

    const aFilterTab = []
    aFilterTab.push({
      id: 'HUB',
      txt: t('HUB'),
      linkRoute: 'listings-listview-myHUBLocation',
    })
    aFilterTab.push({
      id: 'myHUB',
      txt: t('Grupo'),
      linkRoute: 'listings-listview-myHUB',
    })

    if (can('list_my', 'listings')) {
      aFilterTab.push({
        id: 'listByUser',
        txt: t('Minhas'),
        linkRoute: 'listings-listview-list',
      })
    }

    aFilterTab.push({
      id: 'rede',
      txt: t('Rede'),
      linkRoute: 'listings-listview-rede',
    })

    const infoTabSelected = aFilterTab.find(o => o.id === props.filterTab)
    const filterTabSelected = ref({})

    if (infoTabSelected !== undefined) {
      filterTabSelected.value = infoTabSelected
    }

    const listings = ref([])
    const isLoading = ref(true)
    const init = ref(true)
    const listingsData = computed(() => props.data)
    const isErrorRequest = computed(() => props.error)

    watch(listingsData, () => {
      init.value = false
      listings.value = buildDataToListiew(listingsData.value) || []
      isLoading.value = false
    })

    return {
      aFilterTab,
      filterTabSelected,
      listings,
      isLoading,
      init,
      listingsData,
      isErrorRequest,
      buildDataToListiew,
    }
  },
}
</script>

<style lang="scss" scoped>
.flex-container {
  flex: 1;
  min-width: 0;
}

.fontSmall{
 font-size: $small-font-size;
}
</style>
