<template>
  <div>

    <vue-perfect-scrollbar
      ref="scrollDashboard"
      class="embed-responsive overflow-auto"
    >
      <b-container
        fluid
      >
        <b-row>
          <b-col md="12">
            <statusTask
              :data="statusTaskData"
            />
          </b-col>
        </b-row>

        <b-row
          class="match-height"
        >
          <b-col md="4">
            <shortcuts
              @createNewContactLead="openModalCreateNewContactLead"
            />
          </b-col>
          <b-col md="8">
            <b-card
              no-body
              class="business-card"
            >
              <b-card-body
                class="p-1"
              >
                <b-row class="match-height">
                  <b-col
                    md="12"
                    lg="6"
                  >
                    <listAllSeguimentos
                      :refresh.sync="refreshListAllSeguimentos"
                      :view-detail.sync="openViewInfoSeguimento"
                      @showStatusTashFinish="showStatusTashFinish"
                      @activeScrollContent="activeScrollContent"
                      @destroyScrollContent="destroyScrollContent"
                    />
                  </b-col>
                  <b-col
                    md="12"
                    lg="6"
                  >
                    <formCreateSeguimento
                      @refresh="refreshSeguimentos"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row
          v-if="currentUserIsAgent===true"
          class="match-height"
        >
          <b-col
            md="12"
            lg="4"
          >
            <goals
              :data="goalsData"
            />
          </b-col>
          <b-col
            md="12"
            lg="4"
          >
            <topLiner
              :data="goalsData"
            />
          </b-col>
          <b-col
            md="12"
            lg="4"
          >
            <rating />
          </b-col>
        </b-row>

        <b-row
          v-if="currentUserIsAgent===true"
        >
          <b-col md="12">
            <invoicing
              :data="invoiceDataChart"
              @refreshInvoice="getInvoiceChart"
            />
          </b-col>
        </b-row>

        <b-row
          class="match-height"
        >
          <b-col
            md="12"
            lg="4"
          >
            <listings
              :title="$t('Angariações')"
              :show-filter="true"
              :data="listingsData"
              :error="isErrorListingsData"
              :open-view-listing="openViewListing"
              filter-tab="HUB"
              @getListings="getListingsByTab"
            />
          </b-col>
          <b-col
            md="12"
            lg="4"
          >
            <listings
              :title="$t('Descida de preço')"
              :drop-price="true"
              :show-filter="true"
              :data="listingsPriceDrop"
              :error="isErrorListingsPriceDrop"
              :open-view-listing="openViewListing"
              filter-tab="HUB"
              @getListings="getListingsPriceDrop"
              @openModalViewAll="openModalViewAllListingsPriceDrop"
            />
          </b-col>
          <b-col
            v-if="currentUserIsAgent===true"
            md="12"
            lg="4"
          >
            <listings
              :title="$t('Angariações a trabalhar')"
              :drop="true"
              :data="listingsWorking"
              :error="isErrorListingsWorking"
              :open-view-listing="openViewListing"
              filter-tab="listByUser"
              @getListings="getListingsWorking"
            />
          </b-col>
          <b-col
            v-if="currentUserIsAgent===false"
            md="12"
            lg="4"
          >
            <rating />
          </b-col>
        </b-row>
      </b-container>

    </vue-perfect-scrollbar>

    <component
      :is="ModalCriticalNotification"
      v-if="showModalCritical === true"
      ref="modalCriticalNotifications"
    />

    <component
      :is="ModalViewAllListingsPriceDrop"
      v-if="showModalViewAllListingsPriceDrop === true"
      :data="alllistingsPriceDrop"
      @closeModal="closeModalViewAllListingsPriceDrop"
    />

    <component
      :is="ModalCreateNewContactLead"
      v-if="showModalCreateNewContactLead"
      ref="ModalCreateNewContactLead"
      @closeModal="closeModalCreateNewContactLead"
      @openModalSearchContactsDevice="openModalSearchContactsDevice"
    />

    <component
      :is="ModalQuickViewListing"
      v-if="showModalQuickView === true"
      ref="modalQuickViewListing"
      :id-listing="idListing"
      @closeModalQuickView="closeModalQuickView"
    />

    <component
      :is="ModalSearchContactsDevice"
      v-if="searchContactsDevice===true"
      ref="ModalSearchContactsDevice"
      :choose-contact="chooseContactModalSearchContactsDevice"
      :contacts="contactsDevice"
      @close-search-contacts-device="closeModalSearchContactsDevice"
    />

  </div>
</template>

<script>
import store from '@/store'
import {
  BCard, BCardText, BContainer, BRow, BCol, BCardBody,
} from 'bootstrap-vue'
import {
  onUnmounted, ref, computed, watch,
} from '@vue/composition-api'
import dashboardModule from '@store-modules/dashboard/index'
import { mapGetters } from 'vuex'
import { showMsgRequest, activatedScrollBody, disabledScrollBody } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import '@/libs/sweet-alerts'
import formCreateSeguimento from '@/views/modules/crm/leads/form/form_data/seguimentos/formSimple.vue'
import listAllSeguimentos from '@/views/modules/crm/leads/form/form_data/seguimentos/listAll.vue'
import { useRouter } from '@core/utils/utils'
import compareVersions from 'compare-versions'
import checkNested from 'check-nested'
import JSCookieVue from 'js-cookie-vue'
import btoa from 'btoa'
import { eventsDeviceImport } from '@/views/modules/crm/leads/form/form_data/device/mixinDevice'
import statusTask from './components/statusTask.vue'
import shortcuts from './components/shortcuts.vue'
import goals from './components/goals.vue'
import topLiner from './components/topLiner.vue'
import rating from './components/rating.vue'
import invoicing from './components/invoicing.vue'
import listings from './components/listings.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BContainer,
    BCardBody,
    statusTask,
    shortcuts,
    goals,
    topLiner,
    rating,
    invoicing,
    listings,
    formCreateSeguimento,
    listAllSeguimentos,
    VuePerfectScrollbar,
  },
  mixins: [showMsgRequest, eventsDeviceImport],
  data() {
    return {
      showModalCritical: false,
      goalsData: {},
      listingsData: {},
      listingsPriceDrop: {},
      listingsWorking: {},
      isErrorListingsData: false,
      isErrorListingsPriceDrop: false,
      isErrorListingsWorking: false,
      showModalViewAllListingsPriceDrop: false,
      alllistingsPriceDrop: {},
      invoiceDataChart: {},
      showModalCreateNewContactLead: false,
      statusTaskData: {},
      showModalQuickView: false,
      idListing: '',
    }
  },
  computed:
  {
    ...mapGetters('dashboard', ['listingsRestore']),
    currentUserType() {
      const currentUser = this.$store.getters['auth/currentUser']

      if ((currentUser !== null) && (currentUser !== undefined)) {
        if ('id_role' in currentUser) {
          return currentUser.id_role
        }
      }

      return 0
    },
    currentUserIsAgent() {
      if ([4, 7].includes(this.currentUserType)) {
        return true
      }

      return false
    },
  },
  async created() {
    try {
      // await this.getCriticalNotificantions()

      if ([4, 7].includes(this.currentUserType)) {
        await this.getGoalsByUser()
        await this.getListingsByTab('HUB')
        await this.getListingsPriceDrop('HUB')
        await this.getListingsWorking()
        await this.getInvoiceChart(6)
      } else {
        await this.getListingsByTab('HUB')
        await this.getListingsPriceDrop('HUB')
      }

      await this.checkLastVersionApp()
    } catch (err) {
      //
    }
  },
  methods: {
    async getCriticalNotificantions() {
      await store.dispatch(`${this.DASHBOARD_MODULE_NAME}/getCriticalNotificantions`).catch(async error => {
        this.showMsgErrorRequest(error)
      })

      if (Object.keys(this.listingsRestore).length > 0) {
        this.showModalCritical = true

        setTimeout(() => {
          if (this.showModalCritical === true) {
            this.$refs.modalCriticalNotifications.showModal().then(async result => {
              if (result === true) {
                await this.$refs.modalCriticalNotifications.hideModal()
              }
            })
          }
        }, 800)
      }
    },
    async getGoalsByUser() {
      await store.dispatch(`${this.DASHBOARD_MODULE_NAME}/getGoalsByUser`).then(result => {
        this.goalsData = result
      }).catch(async error => {
        this.showMsgErrorRequest(error)
      })
    },
    async getListingsByTab(tab) {
      await store.dispatch(`${this.DASHBOARD_MODULE_NAME}/getListingsByTab`, { tab }).then(result => {
        this.isErrorListingsData = false
        this.listingsData = result
      }).catch(async error => {
        this.isErrorListingsData = true
        this.listingsData = {}
        this.showMsgErrorRequest(error)
      })
    },
    async getListingsPriceDrop(tab) {
      await store.dispatch(`${this.DASHBOARD_MODULE_NAME}/getListingsPriceDrop`, { tab, limit: 5 }).then(result => {
        this.isErrorListingsPriceDrop = false
        this.listingsPriceDrop = result
      }).catch(async error => {
        this.isErrorListingsPriceDrop = true
        this.listingsPriceDrop = {}
        this.showMsgErrorRequest(error)
      })
    },
    async getListingsWorking() {
      await store.dispatch(`${this.DASHBOARD_MODULE_NAME}/getListingsWorking`).then(result => {
        this.isErrorListingsWorking = false
        this.listingsWorking = result
      }).catch(async error => {
        this.isErrorListingsWorking = true
        this.listingsWorking = {}
        this.showMsgErrorRequest(error)
      })
    },
    async openModalViewAllListingsPriceDrop(tab) {
      await store.dispatch(`${this.DASHBOARD_MODULE_NAME}/getListingsPriceDrop`, { tab, limit: '' }).then(result => {
        if (Object.keys(result).length > 0) {
          disabledScrollBody()
          this.alllistingsPriceDrop = result
          this.showModalViewAllListingsPriceDrop = true
        } else {
          activatedScrollBody()
          this.showMsgErrorRequest({ message: this.$t('Não existe descidas de preço') })
        }
      }).catch(async error => {
        activatedScrollBody()
        this.showMsgErrorRequest(error)
      })
    },
    closeModalViewAllListingsPriceDrop() {
      this.showModalViewAllListingsPriceDrop = false
      this.alllistingsPriceDrop = {}
      activatedScrollBody()
    },
    async getInvoiceChart(limitMonth) {
      await store.dispatch(`${this.DASHBOARD_MODULE_NAME}/getReportDashboardInvoice`, { limitMonth }).then(result => {
        this.invoiceDataChart = result
      }).catch(async error => {
        this.invoiceDataChart = {}
        this.showMsgErrorRequest(error)
      })
    },
    openModalCreateNewContactLead() {
      disabledScrollBody()
      this.showModalCreateNewContactLead = true
    },
    closeModalCreateNewContactLead() {
      activatedScrollBody()
      this.showModalCreateNewContactLead = false
      this.getGoalsByUser()
    },
    showStatusTashFinish(result) {
      this.statusTaskData = result
    },
    refreshSeguimentos() {
      this.refreshListAllSeguimentos = true
      this.openViewInfoSeguimento = ''
    },
    activeScrollContent() {
      if (this.$store.state.capacitor.isNative === false) {
        // eslint-disable-next-line no-underscore-dangle
        this.$refs.scrollDashboard.$refs.vuePerfectScrollbarComponentCustom.__init()
      }
    },
    destroyScrollContent() {
      if (this.$store.state.capacitor.isNative === false) {
        // eslint-disable-next-line no-underscore-dangle
        this.$refs.scrollDashboard.$refs.vuePerfectScrollbarComponentCustom.__uninit()
      }
    },
    async checkLastVersionApp() {
      // Mostra alerta da nova versao caso esteja ativa
      if (checkNested(store.state.auth.configServer, 'updateVersionStore.lastVersionApp.active') && (this.$store.getters['capacitor/isNative'] === true)) {
        if (store.state.auth.configServer.updateVersionStore.lastVersionApp.active === true) {
          if (compareVersions(process.env.VUE_APP_VERSION, store.state.auth.configServer.updateVersionStore.lastVersionApp.version) === -1) {
            // Verifica se exista a cookie para ignorar o aviso
            let skipModalUpdateVersion = false
            if ((JSCookieVue.get('modalUpdateVersionIgnore') !== undefined)) {
              if (compareVersions(JSCookieVue.get('modalUpdateVersionIgnore'), store.state.auth.configServer.updateVersionStore.lastVersionApp.version) === 0) {
                skipModalUpdateVersion = true
              }
            }

            if (skipModalUpdateVersion === false) {
              this.$swal({
                title: store.state.auth.configServer.updateVersionStore.lastVersionApp.title,
                html: store.state.auth.configServer.updateVersionStore.lastVersionApp.txt,
                icon: '',
                showCancelButton: false,
                showDenyButton: true,
                reverseButtons: true,
                confirmButtonText: store.state.auth.configServer.updateVersionStore.lastVersionApp.buttonUpdate,
                denyButtonText: store.state.auth.configServer.updateVersionStore.lastVersionApp.buttonClose,
                customClass: {
                  confirmButton: 'btn btn-success ml-sm-1 btn-xs-block',
                  denyButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                  title: 'mb-1',
                  content: 'mb-1',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed === true) {
                  this.linkUpdateStore().then(url => {
                    if (url !== '') {
                      window.open(url)
                    }
                  })
                } else {
                  JSCookieVue.set('modalUpdateVersionIgnore', store.state.auth.configServer.updateVersionStore.lastVersionApp.version, { expires: 1, secure: true })
                }
              })
            }
          }
        }
      }
    },
    async linkUpdateStore() {
      return new Promise(resolve => {
        const configServer = store.getters['auth/getConfigServer']
        const { platform } = store.getters['capacitor/infoDevice']

        if (this.$store.getters['capacitor/isNative'] === true) {
          if (platform.toLowerCase() === 'android') {
            if (checkNested(configServer, 'updateVersionStore.ANDROID')) {
              resolve(configServer.updateVersionStore.ANDROID)
            }
          }

          if (platform.toLowerCase() === 'ios') {
            if (checkNested(configServer, 'updateVersionStore.IOS')) {
              resolve(configServer.updateVersionStore.IOS)
            }
          }

          if (platform.toLowerCase() === 'web') {
            if (checkNested(configServer, 'updateVersionStore.DESKTOP')) {
              resolve(configServer.updateVersionStore.DESKTOP)
            }
          }
        }

        resolve('')
      })
    },
    openViewListing(row) {
      this.idListing = btoa(row.id)
      this.showModalQuickView = true
    },
    closeModalQuickView() {
      this.showModalQuickView = false
    },
    async openModalSearchContactsDevice() {
      await this.getContactsDevice()
      // await this.$refs.ModalCreateNewContactLead.setVisibleModal(false)
    },
    async closeModalSearchContactsDevice() {
      // await this.$refs.ModalCreateNewContactLead.setVisibleModal(true)
      // await this.closeSearchContactsDevice()
    },
    async chooseContactModalSearchContactsDevice(contactSel) {
      await this.$refs.ModalCreateNewContactLead.setVisibleModal(true)
      await this.$refs.ModalCreateNewContactLead.chooseContact(contactSel)
      await this.closeSearchContactsDevice()
    },
  },
  setup() {
    const DASHBOARD_MODULE_NAME = 'dashboard'
    const NAMESPACE_SW025_FORM = 'crm_leads_form_modalCreate'

    if (!store.hasModule(DASHBOARD_MODULE_NAME)) {
      store.registerModule(DASHBOARD_MODULE_NAME, dashboardModule)

      onUnmounted(() => {
        if (store.hasModule(DASHBOARD_MODULE_NAME)) store.unregisterModule(DASHBOARD_MODULE_NAME)
      })
    }

    const ModalCriticalNotification = () => import(/* webpackChunkName: "dashboard-modal-critical-notifications" */ /* webpackPrefetch: true */ './modal/criticalNotification.vue')
    const ModalViewAllListingsPriceDrop = () => import(/* webpackChunkName: "dashboard-modal-view-all-listings-price-drop" */ /* webpackPrefetch: true */ './modal/listingsPriceDrop.vue')
    const ModalCreateNewContactLead = () => import(/* webpackChunkName: "modal-create-new-contact-lead" */ /* webpackPrefetch: true */ '@/views/modules/crm/leads/form/modalCreate.vue')
    const ModalQuickViewListing = () => import(/* webpackChunkName: "modal-quick-view-listing" */ /* webpackPrefetch: true */ '@/views/modules/components/listing/quickView.vue')
    const ModalSearchContactsDevice = () => import(/* webpackChunkName: "crm-leads-new-search-contacts-device" */ /* webpackPrefetch: true */ '@/views/modules/crm/leads/form/form_data/device/searchContactsDevice.vue')

    const { route } = useRouter()
    const routeParamsQuery = computed(() => route.value.query)

    const refreshListAllSeguimentos = ref(false)
    const openViewInfoSeguimento = ref('')

    watch(() => routeParamsQuery.value, newVal => {
      if ((newVal !== null) && (newVal !== undefined)) {
        if ('viewSeguimento' in newVal) {
          openViewInfoSeguimento.value = newVal.viewSeguimento
        }
      }
    }, { immediate: true })

    return {
      DASHBOARD_MODULE_NAME,
      NAMESPACE_SW025_FORM,

      ModalCriticalNotification,
      ModalViewAllListingsPriceDrop,
      ModalCreateNewContactLead,
      ModalSearchContactsDevice,
      ModalQuickViewListing,

      refreshListAllSeguimentos,
      openViewInfoSeguimento,
      routeParamsQuery,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
